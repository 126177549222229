<template>
    <div class="row">
        <div v-if="!isHideDate" class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label 
                for="colFormLabel" 
                class="col-form-label"
                :class="{'col-xl-3' : inEnoughSpace}"
            >
                From
            </label>
            <input
                @change="updateUrl"
                v-model="start"
                type="date"
                class="form-control date-mask" 
                placeholder="From"
                :disabled="isDisabled"
            >
        </div>
        
        <div v-if="!isHideDate" class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label 
                for="colFormLabel"
                class="col-form-label"
                :class="{'col-xl-3' : inEnoughSpace}"
            >
                To
            </label>
            <input
                @change="updateUrl"
                v-model="end"
                type="date"
                class="form-control date-mask" 
                placeholder="From"
                :disabled="isDisabled"
            >   
        </div>
        <slot></slot>
        <div v-if="isVisible" class="col-2 d-flex align-items-end mt-2">
            <button
                style="min-width: 64px;"
                @click="handleDateChange"
                class="btn btn-primary btn-block waves-effect waves-float waves-light"
            >{{btnTitle}}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateQuerySelector',

    props: {
        btnTitle: {
            default: 'Go'
        },
        inEnoughSpace: {
            default: true
        },
        isVisible: {
            type: Boolean,
            default: true
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isHideDate: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        start: '',
        end: ''
    }),

    methods: {
        updateUrl () {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.start = this.start
            routeQuery.end = this.end
            this.$router.push({path: this.$route.path, query: routeQuery})
        },

        handleDateChange() {
            this.$emit('onClickGo')
        }
    },

    mounted() {
        this.start = this.$route.query.start,
        this.end = this.$route.query.end
    }
}
</script>
